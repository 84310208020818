table {
  @apply w-full border-separate border-spacing-0 bg-white md:rounded-lg overflow-hidden;
  max-width: 100%;

  tr {
    @apply border-b;
    border-color: $light;
    &:last-child {
      // border: none;
      @apply pb-0 mb-0;
    }
    th {
      @apply py-3 px-4;
      @apply text-left;
      @apply font-medium;
    }
    td {
      @apply py-4 px-2;
      > div,
      > a > * {
        @apply items-center;
      }
    }
  }
}

.table_scrollable {
  @apply overflow-x-auto mb-5;
}

/* TABLE HEADER */
.table-header {
  @apply font-roboto;
  background: $blue;
  // background: linear-gradient(
  //   0deg,
  //   rgba(17, 73, 122, 1) 00%,
  //   rgba(5, 123, 162, 1) 100%
  // );
  // @apply uppercase;
  @apply text-white;
  // @apply rounded-t;
  // @apply rounded-md;

  &.row_item {
    @apply my-5 p-3;
  }

  .table-icon {
    @apply text-white;
    @apply text-2xl;
    @apply align-top;
  }
}

/* TABLE ROW */
.row_item {
  @apply mb-5 pb-5 px-3;
  // @apply border-b;
  // border-color: $light;

  &:last-child {
    // border: none;
    @apply pb-0 mb-0;
  }
}

.table-number {
  @apply font-antonio;
}

.game {
  @apply rounded-md border-solid border-2 px-4 py-4 my-8;
  border-color: $light;
}

.table-rank-up {
  color: $green;
}

.winning {
  background-color: $green;
  @apply rounded-md text-white;
}
.losing {
  background-color: $red;
  @apply rounded-md text-white;
}
