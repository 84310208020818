/*** COLORS ***/

$primary: #051726;
$blue: #057ba2;
$dark_blue: #11497a;
$orange: #f27405;
$yellow: #fdb910;
$green: #7cb518; //TODO: Add real green
$red: #e83f6f; //TODO: Add real red
$light: #d3d6d9;
$grey: #f5f5f5;
