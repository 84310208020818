.score {
  @apply font-antonio;
  color: $dark_blue;
  @apply pb-2;
}

.score-post-win {
  @apply relative;
  &:after {
    content: "";
    @apply absolute bottom-0 left-0;
    @apply w-full;
    height: 3px;
    background: rgb(5, 23, 38);
    background: linear-gradient(
      90deg,
      rgba(5, 23, 38, 1) 10%,
      rgba(17, 73, 122, 1) 100%
    );
  }
}
