form {
  label {
    @apply mb-1;
    @apply block;
  }

  select {
    @apply appearance-none;
    @apply mb-5;
    @apply px-5 py-2.5;
    @apply rounded-none rounded-tr-lg;
    @apply w-full;
    line-height: initial;
  }

  .selectTrigger {
    @apply mb-5;
    @apply w-full;
    @apply text-left;
    @apply relative;

    .select-icon {
      @apply absolute;
      right: 15px;
    }
  }

  textarea {
    @apply mb-5 w-full;
    background-color: $grey;
  }
}

input {
  border-bottom: 1px solid $primary;
  @apply rounded-t-lg;
  @apply px-5 py-2.5;
  background-color: $grey;
  @apply mb-5;
  @apply w-full;
  line-height: initial;

  &:focus-visible {
    @apply outline-none;
    background-color: $grey;
  }

  &.btn {
    border: none;
  }
}

input:disabled,
select:disabled,
textarea:disabled {
  @apply bg-bg-gray hover:cursor-not-allowed;
}

select {
  /* styling */
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  option {
    @apply bg-white;
    @apply mb-5 pb-5;
    @apply border-b;
    border-color: $light;
  }
}

/* arrows */

select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, $primary 50%),
    linear-gradient(135deg, $primary 50%, transparent 50%),
    linear-gradient(to right, $primary, $primary);
  background-position: calc(100% - 20px) calc(1em + 5px),
    calc(100% - 15px) calc(1em + 5px), calc(100% - 2.5em) 0.75em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

/*select.minimal:focus {
    background-image:
        linear-gradient(45deg, $primary 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, $primary 50%),
        linear-gradient(to right, $primary, $primary);
    background-position:
        calc(100% - 15px) 1em,
        calc(100% - 20px) 1em,
        calc(100% - 2.5em) 0.5em;
    background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
    background-repeat: no-repeat;
    border-color: $primary;
    outline: 0;
}*/

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 $primary;
}

/* SELECT */

.selectTrigger {
  background-color: $grey;
  border-bottom: 1px solid $primary;
  @apply px-5 py-2.5;
  background-color: $grey;
  @apply rounded-t;
  line-height: initial;
  outline: none;

  &.white {
    @apply bg-white;
  }
}

.selectViewport {
  background-color: $grey;
  border: 1px solid $primary;
  @apply drop-shadow-md;
  @apply px-2 z-50;
  pointer-events: all;

  div {
    @apply p-2.5;
    border-bottom: 1px solid $light;
    @apply bg-transparent;
    @apply transition ease-in-out delay-150 hover:bg-blue hover:text-white duration-300;

    &:hover,
    &:focus {
      outline: none;
    }

    &:last-child {
      @apply border-none;
    }
  }
}
