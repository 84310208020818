/* LIST */

.newsteaser {
  @apply border-b md:border-none mt-4 pb-4;
  border-color: rgba(5, 23, 38, 0.15);
  @apply md:bg-white;

  &:last-child {
    @apply border-none;
  }

  .newsteaser_thumb {
    @apply w-full h-full relative;
    min-height: 90px;
    @apply md:h-36;

    .newsteaser_img {
      @apply object-cover;
      @apply m-0;
    }
  }

  .newsteaser_date,
  .newsteaser_category {
    @apply font-antonio;
    // @apply text-sm;
  }

  .newsteaser_category {
    // @apply mt-1;
    @apply mb-1;
  }

  .newsteaser_date {
    @apply leading-none;
  }
}
