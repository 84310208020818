/* FOOTER */

footer {
  background-color: $primary;
  @apply text-white text-sm;
  @apply py-8 mt-10;

  .footer_logo {
    height: 55px;
    @apply w-auto;
    @apply mb-5 md:mb-0;
  }

  .footer_logo_partner {
    height: 13px;
    @apply w-auto;
    @apply h-3.5 md:h-4;

    &.logo_liebherr {
      @apply h-2.5 md:h-3;
    }

    &.logo_auto {
      @apply h-6 md:h-7;
    }

    &.logo_made {
      @apply h-8 md:h-10;
    }
  }

  h3 {
    @apply text-base;
  }

  .social-icons {
    @apply text-2xl;
    @apply leading-none;
  }

  a {
    @apply ease-in-out duration-300;

    &:hover {
      color: $blue;
    }
  }

  hr {
    border-color: $blue;
  }

  .footer_nav {
    @apply font-antonio;
    @apply uppercase;
    @apply text-lg;
  }
}
