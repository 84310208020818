@tailwind base;
@tailwind components;
@tailwind utilities;

@import "colors";
@import "navigation";
@import "table";
@import "forms";
@import "buttons";
@import "scoreboard";
@import "news";
@import "footer";

:root {
  --primary: #051726;

  --gray-light: #dddddd;
  --gray-dark: #323232;
  --gray-text: #c0c0c0;

  --darkBlue-text: #091725;

  --pokal-first: #135889;
  --pokal-last: #051726;

  --bundesliga-first: #fdb910;
  --bundesliga-last: #f27405;

  --orange-first: #fdb910;
  --orange-last: #f27405;

  --darkBlue-first: #135889;
  --darkBlue-last: #051726;

  --lightBlue-first: #057ba2;
  --lightBlue-last: #11497a;

  --text-dark: #535353;

  --dyn: #012f60;

  --dark-blue: #11497a;

  --orange: #f27405;
  --yellow: #fdb910;

  --green: #7cb518; //TODO: Add real green
  --red: #e83f6f; //TODO: Add real red
}

body {
  color: $primary;
  background-color: $grey;
  @apply lg:bg-white;
  @apply font-roboto;
  font-weight: normal;
  font-size: 16px;
}

select,
textarea {
  border-bottom: 1px solid $primary;
  @apply px-5 py-3;
  @apply bg-white rounded-t-lg;
}

.container .bg-white {
  select,
  textarea {
    background-color: $grey;
    position: relative;

    &:after {
      content: ">";
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
    }
  }
}

.dialog-overlay {
  @apply fixed inset-0;
  background-color: hsla(0, 0%, 0%, 0.66);
}

.dialog-content {
  @apply bg-white rounded-lg fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-[70%] my-10 overflow-y-scroll;
}

h1,
h2 {
  @apply font-antonio;
}

h1 {
  @apply uppercase;
  @apply text-3xl md:text-4xl;
  @apply mb-5 md:mb-8;
}

h2 {
  @apply uppercase;
  @apply text-2xl md:text-3xl;
  @apply mb-5 md:mb-8;
}

h3 {
  @apply uppercase;
  @apply text-xl md:text-2xl;
  @apply my-4;
}

p,
ol {
  @apply mb-2;
}

ol {
  @apply pl-4;
  li {
    @apply list-decimal;
    @apply mb-2;
  }
}

ul {
  @apply pl-4;
}

hr {
  @apply py-1;
  border-color: rgba(5, 23, 38, 0.15);
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: initial;
  vertical-align: inherit;
}

.text-active-red {
  color: $orange;
}

.error {
  color: $orange;
}

.infotext {
  color: $light;
  @apply text-base -mt-1;
}

.container {
  @apply mx-auto px-4 md:px-4 lg:max-w-[1200px];

  &.container-no-padding {
    @apply px-0;
  }
  &.container-padding-responsive {
    @apply px-0 md:px-4;
  }

  &.container_content {
    @apply mt-5 md:mt-10;

    h2,
    h3 {
      @apply mt-5;

      &:first-child {
        @apply mt-0;
      }
    }

    h4 {
      @apply mb-5;
    }

    a {
      color: $blue;
      @apply ease-in-out duration-300;

      &:hover {
        color: $yellow;
      }
    }
  }

  &.container_py {
    @apply py-5 md:py-10;
  }
}
.container-xl {
  @apply xl:max-w-[1400px];
  @apply w-full;
  @apply mx-auto;
}

.container_admin {
  @apply rounded-lg bg-white py-8 mt-5 mb-10;
}
.divider {
  max-width: 150px;
  height: 2px;
  @apply bg-gray-light;
  @apply my-8 md:my-10;
  @apply rounded-full;
  @apply mx-auto;
}

.small-text {
  @apply max-w-[900px] lg:mx-auto;
  figure {
    @apply my-4;
  }
  h3 {
    @apply normal-case;
  }
  h2 {
    @apply lg:m-0 lg:mb-4;
  }
}
