.brand {
  @apply font-antonio;
}

.hr-horizontal {
  width: 3px;
  @apply h-1/2 rounded-full inline-block;
  background-color: $light;
}

/* BE NAV */
.beNav {
  a {
    &:first-child {
      @apply ml-0;
    }
  }
}

.secondaryBtn {
  @apply font-antonio;
  @apply uppercase mx-3 leading-none hover:cursor-pointer;

  &:after {
    content: "";
    height: 3px;
    background-color: transparent;
    width: 1%;
    @apply mt-2 inline-block rounded-full;
    -webkit-transition: all 400ms ease-in-out;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
  }

  &:hover {
    &:after {
      width: 100%;
      background-color: $blue;
    }
  }

  &.active {
    &:after {
      content: "";
      height: 3px;
      background-color: $yellow;
      width: 100%;
      @apply inline-block rounded-full;
    }
  }
}

.primaryBtn {
  @apply font-antonio;
  @apply uppercase mx-3 py-1.5 text-lg hover:cursor-pointer;

  &.active {
    border-bottom: 3px solid $blue;
  }
}

/* SERVICE NAV */
.serviceNav {
  @apply flex gap-4 mr-4;

  button {
    @apply text-lg hover:cursor-pointer;
  }

  .secondaryBtn {
    @apply mt-4 mr-0;
  }
}

/* SUB NAV */

.subNav,
.beSubNav {
  a {
    &:first-child {
      @apply ml-0;
    }
  }

  h1 {
    @apply mb-0;
  }

  .primaryBtn,
  .secondaryBtn {
    @apply font-roboto;
    margin-bottom: -2px;
    @apply after:content-none normal-case px-4 py-2 border-transparent hover:border-neutral-300 border-[1px] rounded-md;

    &.active {
      @apply rounded-md border-neutral-300 border-[1px] bg-white;
    }
  }
}

.beSubNav {
  h2 {
    @apply mr-4 mb-0 leading-none;
  }
}
// Be Subnav
// .beSubNav {
//   a {
//     &:first-child {
//       @apply ml-0;
//     }
//   }

//   .primaryBtn,
//   .secondaryBtn {
//     margin-bottom: -2px;
//     @apply py-0;

//     &.active {
//       &:after {
//         background-color: $yellow;
//       }
//     }
//   }
// }

/* MOBILE NAV */
.mobileNav {
  .btn {
    @apply py-2 px-3;
    @apply transition ease-in-out delay-150 hover:-translate-y-0 hover:scale-100 duration-300;
  }

  .nav-title {
    @apply font-antonio;
    @apply text-3xl;
  }
}
