.btn {
  @apply font-antonio;
  background: $blue;
  // background: linear-gradient(
  //   0deg,
  //   rgba(17, 73, 122, 1) 00%,
  //   rgba(5, 123, 162, 1) 100%
  // );
  @apply uppercase;
  @apply text-white;
  @apply text-base;
  @apply rounded-lg;
  @apply py-3 px-6;
  @apply transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300;

  &:disabled {
    @apply bg-gray-400;
    @apply cursor-not-allowed;
  }

  &.btnAction {
    background: rgb(242, 116, 5);
    background: linear-gradient(
      0deg,
      rgba(242, 116, 5, 1) 0%,
      rgba(253, 185, 16, 1) 100%
    );
  }

  &.btnExternal {
    @apply flex justify-around;
    @apply text-base;
    @apply items-center;

    &.btnDYN {
      background: #012f60;
      img {
        @apply h-4 w-auto;
      }
    }

    &.btnYoutube {
      background: #ff0000;
    }
  }

  &.btnNav {
    @apply py-2;
  }
}

.btnDefault {
  @apply font-antonio;
  @apply uppercase;
  @apply text-base;
  border: 2px solid $primary;
  @apply rounded-full;
  @apply py-3 px-6;
  @apply transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300;
}

.btnSecondary {
  @apply font-antonio;
  background: rgb(5, 23, 38);
  background: linear-gradient(
    0deg,
    rgba(5, 23, 38, 1) 10%,
    rgba(151, 159, 165, 1) 100%
  );
  @apply scale-0;
  @apply uppercase;
  @apply text-white;
  @apply text-base;
  @apply rounded-full;
  @apply py-3 px-6;
  @apply transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300;
  transform: none;
  display: inline-block;
}

.container.container_content a {
  &.btnSecondary {
    @apply text-white hover:text-white;
  }
}

.btnTertiary {
  color: $blue;

  &:hover {
    @apply underline;
  }
}

.btnNavSeason {
  @apply font-antonio;
  @apply text-white rounded-sm py-1 px-3 h-fit;
  background-color: $blue;
  @apply transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300;
}
